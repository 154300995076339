
::v-deep .customTable td:first-child>.cell {
    padding-left: 25px;
}

::v-deep .customTable th:first-child>.cell {
    padding-left: 25px;
}

::v-deep .pages-center {
    display: flex;
    justify-content: center;
    margin-top: 24px;
}

.goods-container {
    height: calc(100vh - 140px);
    display: flex;

    .script-box {
        width: 600px;
        height: 100%;
        margin-right: 20px;
    }

    .coupon-container {
        background-color: #ffffff;
        padding: 0px 0 0 30px;
        width: 100%;
        height: 100%;
        flex: 1;
        border-radius: 4px;
    }

    .goods-table {
        width: 100%;
        height: 100%;
        flex: 1;
        overflow: hidden;
    }

    .nav-box {
        display: flex;
    }

    .goodsInfo {
        display: flex;
        align-items: center;
        cursor: pointer;

        img {
            width: 58px;
            height: 58px;
            border-radius: 4px;
        }

        span {
            margin-left: 10px;
        }
    }

    .footer {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 100px;
    }
}

.goodsInfo {
    display: flex;
    align-items: center;
    cursor: pointer;

    img {
        width: 58px;
        height: 58px;
    }

    span {
        margin-left: 10px;
    }
}
